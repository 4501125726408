import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Operation } from "../../../lib/api/types";
import { useNotificationStore } from "../../../stores/notifications";

export interface UseApproveOperationFormData {
  operation: Operation;
}

interface SubmitError {
  message: string;
}

async function submitApproveFormData(formData: UseApproveOperationFormData): Promise<void> {
  await httpClientInstance.approve(formData.operation, {
    approve: formData.operation.name,
  });
}

async function submitCancelFormData(formData: UseApproveOperationFormData): Promise<void> {
  await httpClientInstance.approve(formData.operation, {
    challenge: formData.operation.name,
  });
}

export function useApproveOperation(): UseMutationResult<
  void,
  SubmitError,
  UseApproveOperationFormData
> {
  const { addNotification } = useNotificationStore();
  return useMutation<void, SubmitError, UseApproveOperationFormData>({
    mutationFn: submitApproveFormData,
    onSuccess: () => {
      console.log("Form submitted successfully");
      addNotification({
        type: "success",
        title: `Approval Submitted successfully`,
      });
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting form:", error.message);
      addNotification({
        type: "error",
        title: error.message,
      });
    },
  });
}

export function useCancelOperation(): UseMutationResult<
  void,
  SubmitError,
  UseApproveOperationFormData
> {
  const { addNotification } = useNotificationStore();
  return useMutation<void, SubmitError, UseApproveOperationFormData>({
    mutationFn: submitCancelFormData,
    onSuccess: () => {
      addNotification({
        type: "success",
        title: `Cancellation submitted successfully`,
      });
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting form:", error.message);
      addNotification({
        type: "error",
        title: error.message,
      });
    },
  });
}
