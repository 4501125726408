import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface DeleteAccountFormData {
  name: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: DeleteAccountFormData) {
  return await httpClientInstance.deleteAccount([formData.name]);
}

export function useDeleteAccount(): UseMutationResult<
  Awaited<ReturnType<typeof submitFormData>>,
  SubmitError,
  DeleteAccountFormData
> {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<
    Awaited<ReturnType<typeof submitFormData>>,
    SubmitError,
    DeleteAccountFormData
  > = {
    mutationFn: submitFormData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      console.log("Account deleted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error deleting account:", error.message);
    },
  };

  return useMutation(mutationOptions);
}
