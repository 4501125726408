import {
  Button,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import React from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import { useNavigate, useParams } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import CPage from "../../components/CPage";
import { formatElapsedTime } from "../../utils/formater";
import { useAccountPage } from "../accountsManagement/api/getAccounts";
import { useAddress } from "../accountsManagement/api/getAddress";
import { useAsset } from "../assetManagement/api/getAsset";
import { useTransferRule } from "../policyManagement/api/getTransferRule";
import { useTransferTransactions } from "../transactionManagment/api/getTransferTransactions";
import {
  useApproveOperation,
  UseApproveOperationFormData,
  useCancelOperation,
} from "./api/createApproveCancelApprovals";
import { useOperation } from "./api/getOperation";
import UserDisplayNameRenderer from "./components/UserDisplayNameRenderer";
import UserRenderer from "./components/UserRenderer";

const ApprovalDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { data: operation, isLoading, error, isError } = useOperation({ operationId: id! });
  const { mutate: approveMutate } = useApproveOperation();
  const { mutate: cancelMutate } = useCancelOperation();

  const darkMode = useDarkMode(false, {
    classNameDark: "cds--g90",
    classNameLight: "cds--g10",
  });

  const lightThemeStyles = {
    variables: {
      light: {
        diffViewerBackground: "#ffffff", // White
        diffViewerColor: "#121212", // Gray 1000 from Carbon
        addedBackground: "#dff0d8", // Green 10
        addedColor: "#24a148", // Green 60
        removedBackground: "#f4c7c3", // Red 10
        removedColor: "#da1e28", // Red 60
        wordAddedBackground: "#c8f0d0", // Green 20
        wordRemovedBackground: "#ffd7d9", // Red 20
      },
    },
  };
  const deleteThemeStyles = {
    variables: {
      light: {
        diffViewerBackground: "#121212", // Gray 1000 from Carbon
        diffViewerColor: "#ffffff", // White
        addedBackground: "#4d0000", // Green 100
        addedColor: "#FFFFFF", // Green 50
        removedBackground: "#4d0000", // Red 100
        removedColor: "#ff8389", // Red 50
        wordAddedBackground: "#FFFFFF", // Green 80
        wordRemovedBackground: "#750e13", // Red
        gutter: "#FFFFFF",
      },
    },
  };

  const darkThemeStyles = {
    variables: {
      light: {
        diffViewerBackground: "#121212", // Gray 1000 from Carbon
        diffViewerColor: "#ffffff", // White
        addedBackground: "#003a22", // Green 100
        addedColor: "#42be65", // Green 50
        removedBackground: "#4d0000", // Red 100
        removedColor: "#ff8389", // Red 50
        wordAddedBackground: "#005f38", // Green 80
        wordRemovedBackground: "#750e13", // Red
        gutter: "#750e13",
      },
    },
  };

  const requestAction = operation?.request?.action;
  const requestBody = operation?.request?.body;
  const requestRessourceType = operation?.request?.resource;

  const isSplitView = requestAction === "update" ? true : false;
  const isDelete = requestAction === "delete" ? true : false;
  let themeStyles = darkMode.value ? darkThemeStyles : lightThemeStyles;
  themeStyles = isDelete ? deleteThemeStyles : themeStyles;

  let previousObjectStateIdentifier;

  try {
    const requestBodyObject = JSON.parse(requestBody!);
    previousObjectStateIdentifier = requestBodyObject.name;
  } catch (error) {
    console.error("Failed to parse requestBody as JSON:", error);
  }

  const formatData = (data: string): string => {
    return JSON.stringify(data, null, 2);
  };

  const { data: policy } = useTransferRule({
    transferId: previousObjectStateIdentifier,
    pageSize: 1,
  });

  const { data: account } = useAccountPage({
    filter: `name="${previousObjectStateIdentifier}"`,
  });

  const { data: address } = useAddress({
    addressId: previousObjectStateIdentifier,
  });

  const { data: asset } = useAsset({ assetId: previousObjectStateIdentifier, pageSize: 1 });

  const { data: transaction } = useTransferTransactions({
    name: previousObjectStateIdentifier,
  });

  let previousObjectState;

  if (requestRessourceType === "TransferRule") {
    //@ts-expect-error error TS2532: Object is possibly 'undefined'.
    previousObjectState = policy ? formatData(policy) : "";
  }

  if (requestRessourceType === "Account") {
    //@ts-expect-error error TS2532: Object is possibly 'undefined'.
    previousObjectState = account ? formatData(account.accounts[0]) : "";
  }

  if (requestRessourceType === "Asset") {
    //@ts-expect-error error TS2532: Object is possibly 'undefined'.
    previousObjectState = asset ? formatData(asset) : "";
  }

  if (requestRessourceType === "Address") {
    //@ts-expect-error error TS2532: Object is possibly 'undefined'.
    previousObjectState = address ? formatData(address) : "";
  }

  if (requestRessourceType === "Transfer") {
    //@ts-expect-error error TS2532: Object is possibly 'undefined'.
    previousObjectState = transaction ? formatData(transaction) : "";
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {error?.message}</div>;
  }

  if (!operation) {
    return <div>Approval not found</div>;
  }

  const handleApprove = (event: React.MouseEvent) => {
    event.stopPropagation();
    const formData: UseApproveOperationFormData = {
      operation: operation,
    };
    approveMutate(formData);
  };

  const handleCancel = (event: React.MouseEvent) => {
    event.stopPropagation();
    const formData: UseApproveOperationFormData = {
      operation: operation,
    };
    cancelMutate(formData);
  };

  const actionColors: { [key: string]: string } = {
    create: "green",
    update: "orange",
    delete: "red",
  };

  const resourceType = operation.request?.resource ? ` of type ${operation.request.resource}` : "";
  const resourceParent = operation.request?.parent ? ` for ${operation.request.parent}` : "";
  const actionColor = actionColors[operation.request?.action || ""];
  const actionSentence = ` wants to <span style="color: ${actionColor};">${operation.request?.action}</span> ${resourceType}${resourceParent}. Would you like to approve this action?`;
  // ${ParseName(operation.initiator, "User").resourceId}
  return (
    <CPage title={`Approval Details`}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/approvals")}>
          Back to Approvals
        </Button>
      </div>
      <div style={{ paddingTop: "20px", paddingBottom: "30px", fontWeight: "bold" }}>
        <UserDisplayNameRenderer userName={operation.initiator} />
        <span dangerouslySetInnerHTML={{ __html: actionSentence }} />
      </div>
      <StructuredListWrapper>
        <StructuredListHead></StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell head>Approvers</StructuredListCell>
            <StructuredListCell>
              {operation.approve?.map((approver) => (
                <UserRenderer key={approver} userName={approver} />
              ))}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Body</StructuredListCell>
            <StructuredListCell>
              <ReactDiffViewer
                styles={themeStyles}
                oldValue={previousObjectState}
                newValue={formatData(JSON.parse(operation.request?.body ?? ""))}
                compareMethod={DiffMethod.WORDS_WITH_SPACE}
                splitView={isSplitView}
              />
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredListWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          gap: "1rem",
          paddingTop: "1rem",
        }}
      >
        <div style={{ display: "flex", gap: "1rem" }}>
          <span style={{ fontSize: "11px" }}>
            Initiated {formatElapsedTime(operation.create_time ?? "")}
          </span>
          <span style={{ fontSize: "11px" }}>
            Last approved {formatElapsedTime(operation.update_time ?? "")}
          </span>
        </div>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" onClick={handleApprove}>
            Approve
          </Button>
          <Button size="sm" kind="danger" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </CPage>
  );
};

export default ApprovalDetails;
