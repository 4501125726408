import { ParseName } from "../lib/api/name";
import { cordialApisClient } from "../lib/cordialapis/api";
import { ChainList, ExplorerUrlKey } from "../lib/cordialapis/connector/types";

let cachedExplorerUrls: ChainList | null = null;

export async function loadExplorerUrls() {
  try {
    const chainsData = await cordialApisClient.fetchChains();
    cachedExplorerUrls = chainsData;
  } catch (err) {
    console.error("Failed to load explorer urls. Using fallback values instead.", err);

    if (cordialApisClient.network === "!mainnet") cachedExplorerUrls = testnetFallbackUrls;
    else cachedExplorerUrls = mainnetFallbackUrls;
  }
}

export function getExplorerUrl(chainId: string, type: ExplorerUrlKey, id: string) {
  const chain = cachedExplorerUrls?.chains?.find(
    (chain) => ParseName([chain.name!], "Chain").resourceId === chainId,
  );
  if (!chain?.explorer_urls?.[type]) {
    return "";
  }

  return substitute(chain.explorer_urls[type]!, id);
}

function substitute(template: string, value: string): string {
  if (template.indexOf("{:base64}") != -1) {
    value = btoa(value);
    return template.replace("{:base64}", value);
  }
  return template.replace("{}", value);
}

const mainnetFallbackUrls: ChainList = {
  chains: [
    {
      name: "chains/ACA",
      network: "mainnet",
      symbol: "ACA",
      description: "Acala",
      chain_id: "787",
      explorer_urls: {
        tx: "https://blockscout.acala.network/tx/{}",
        address: "https://blockscout.acala.network/address/{}",
        token: "https://blockscout.acala.network/address/{}",
      },
    },
    {
      name: "chains/APTOS",
      network: "mainnet",
      symbol: "APTOS",
      description: "Aptos",
      chain_id: "1",
      explorer_urls: {
        tx: "https://aptoscan.com/transaction/{}",
        address: "https://aptoscan.com/account/{}",
        token: "https://aptoscan.com/coin/{}",
      },
    },
    {
      name: "chains/ATOM",
      network: "mainnet",
      symbol: "ATOM",
      description: "Cosmos",
      chain_id: "cosmoshub-4",
      explorer_urls: {
        tx: "https://www.mintscan.io/cosmos/tx/{}",
        address: "https://www.mintscan.io/cosmos/address/{}",
        token: "https://www.mintscan.io/cosmos/assets/{:base64}",
      },
    },
    {
      name: "chains/AVAX",
      network: "mainnet",
      symbol: "AVAX",
      description: "Avalanche C-Chain",
      chain_id: "43114",
      explorer_urls: {
        tx: "https://snowtrace.io/tx/{}",
        address: "https://snowtrace.io/address/{}",
        token: "https://snowtrace.io/token/{}",
      },
    },
    {
      name: "chains/ArbETH",
      network: "mainnet",
      symbol: "ArbETH",
      description: "Arbitrum",
      chain_id: "42161",
      explorer_urls: {
        tx: "https://arbiscan.io/tx/{}",
        address: "https://arbiscan.io/address/{}",
        token: "https://arbiscan.io/token/{}",
      },
    },
    {
      name: "chains/AurETH",
      network: "mainnet",
      symbol: "AurETH",
      description: "Aurora",
      chain_id: "1313161554",
      explorer_urls: {
        tx: "https://explorer.mainnet.aurora.dev/tx/{}",
        address: "https://explorer.mainnet.aurora.dev/address/{}",
        token: "https://explorer.mainnet.aurora.dev/token/{}",
      },
    },
    {
      name: "chains/BCH",
      network: "mainnet",
      symbol: "BCH",
      description: "Bitcoin Cash",
      explorer_urls: {
        tx: "https://blockchair.com/bitcoin-cash/transaction/{}",
        address: "https://blockchair.com/bitcoin-cash/address/{}",
      },
    },
    {
      name: "chains/BNB",
      network: "mainnet",
      symbol: "BNB",
      description: "Binance Smart Chain",
      chain_id: "56",
      explorer_urls: {
        tx: "https://bscscan.com/tx/{}",
        address: "https://bscscan.com/address/{}",
        token: "https://bscscan.com/token/{}",
      },
    },
    {
      name: "chains/BTC",
      network: "mainnet",
      symbol: "BTC",
      description: "Bitcoin",
      explorer_urls: {
        tx: "https://mempool.space/tx/{}",
        address: "https://mempool.space/address/{}",
      },
    },
    {
      name: "chains/CELO",
      network: "mainnet",
      symbol: "CELO",
      description: "Celo",
      chain_id: "42220",
      explorer_urls: {
        tx: "https://celoscan.io/tx/{}",
        address: "https://celoscan.io/address/{}",
        token: "https://celoscan.io/token/{}",
      },
    },
    {
      name: "chains/CHZ2",
      network: "mainnet",
      symbol: "CHZ2",
      description: "Chiliz 2.0",
      chain_id: "88888",
      explorer_urls: {
        tx: "https://chiliscan.com/tx/{}",
        address: "https://chiliscan.com/address/{}",
        token: "https://chiliscan.com/token/{}",
      },
    },
    {
      name: "chains/DOGE",
      network: "mainnet",
      symbol: "DOGE",
      description: "Dogecoin",
      explorer_urls: {
        tx: "https://blockchair.com/dogecoin/transaction/{}",
        address: "https://blockchair.com/dogecoin/address/{}",
      },
    },
    {
      name: "chains/DOT",
      network: "mainnet",
      symbol: "DOT",
      description: "Polkadot",
      explorer_urls: {
        tx: "https://polkadot.subscan.io/extrinsic/{}",
        address: "https://polkadot.subscan.io/account/{}",
      },
    },
    {
      name: "chains/ETC",
      network: "mainnet",
      symbol: "ETC",
      description: "Ethereum Classic",
      chain_id: "61",
      explorer_urls: {
        tx: "https://etc.blockscout.com/tx/{}",
        address: "https://etc.blockscout.com/address/{}",
        token: "https://etc.blockscout.com/token/{}",
      },
    },
    {
      name: "chains/ETH",
      network: "mainnet",
      symbol: "ETH",
      description: "Ethereum",
      chain_id: "1",
      explorer_urls: {
        tx: "https://etherscan.com/tx/{}",
        address: "https://etherscan.com/address/{}",
        token: "https://etherscan.com/token/{}",
      },
    },
    {
      name: "chains/ETHW",
      network: "mainnet",
      symbol: "ETHW",
      description: "EthereumPOW",
      chain_id: "10001",
      explorer_urls: {
        tx: "https://www.oklink.com/ethw/tx/{}",
        address: "https://www.oklink.com/ethw/address/{}",
        token: "https://www.oklink.com/ethw/token/{}",
      },
    },
    {
      name: "chains/EmROSE",
      network: "mainnet",
      symbol: "EmROSE",
      description: "Oasis",
      chain_id: "42262",
      explorer_urls: {
        tx: "https://explorer.oasis.io/mainnet/emerald/tx/{}",
        address: "https://explorer.oasis.io/mainnet/emerald/address/{}",
        token: "https://explorer.oasis.io/mainnet/emerald/token/{}",
      },
    },
    {
      name: "chains/FTM",
      network: "mainnet",
      symbol: "FTM",
      description: "Fantom",
      chain_id: "250",
      explorer_urls: {
        tx: "https://ftmscan.com/tx/{}",
        address: "https://ftmscan.com/address/{}",
        token: "https://ftmscan.com/token/{}",
      },
    },
    {
      name: "chains/HASH",
      network: "mainnet",
      symbol: "HASH",
      description: "Provenance",
      chain_id: "pio-mainnet-1",
      explorer_urls: {
        tx: "https://explorer.provenance.io/tx/{}",
        address: "https://explorer.provenance.io/accounts/{}",
      },
    },
    {
      name: "chains/INJ",
      network: "mainnet",
      symbol: "INJ",
      description: "Injective",
      chain_id: "injective-1",
      explorer_urls: {
        tx: "https://explorer.injective.network/transaction/{}",
        address: "https://explorer.injective.network/account/{}",
        token: "https://explorer.injective.network/asset/?denom={}",
      },
    },
    {
      name: "chains/KAR",
      network: "mainnet",
      symbol: "KAR",
      description: "Karura",
      chain_id: "686",
      explorer_urls: {
        tx: "https://blockscout.karura.network/tx/{}",
        address: "https://blockscout.karura.network/address/{}",
        token: "https://blockscout.karura.network/address/{}",
      },
    },
    {
      name: "chains/KLAY",
      network: "mainnet",
      symbol: "KLAY",
      description: "Klaytn",
      chain_id: "8217",
      explorer_urls: {
        tx: "https://klaytnscope.com/tx/{}",
        address: "https://klaytnscope.com/account/{}",
        token: "https://klaytnscope.com/token/{}",
      },
    },
    {
      name: "chains/KSM",
      network: "mainnet",
      symbol: "KSM",
      description: "Kusama",
      explorer_urls: {
        tx: "https://kusama.subscan.io/extrinsic/{}",
        address: "https://kusama.subscan.io/account/{}",
      },
    },
    {
      name: "chains/LTC",
      network: "mainnet",
      symbol: "LTC",
      description: "Litecoin",
      explorer_urls: {
        tx: "https://blockchair.com/litecoin/transaction/{}",
        address: "https://blockchair.com/litecoin/address/{}",
      },
    },
    {
      name: "chains/LUNA",
      network: "mainnet",
      symbol: "LUNA",
      description: "Terra",
      chain_id: "phoenix-1",
      explorer_urls: {
        tx: "https://finder.terra.money/mainnet/tx/{}",
        address: "https://finder.terra.money/mainnet/address/{}",
        token: "https://finder.terra.money/mainnet/address/{}",
      },
    },
    {
      name: "chains/LUNC",
      network: "mainnet",
      symbol: "LUNC",
      description: "Terra Classic",
      chain_id: "columbus-5",
      explorer_urls: {
        tx: "https://finder.terra.money/classic/tx/{}",
        address: "https://finder.terra.money/classic/address/{}",
        token: "https://finder.terra.money/classic/address/{}",
      },
    },
    {
      name: "chains/MATIC",
      network: "mainnet",
      symbol: "MATIC",
      description: "Polygon",
      chain_id: "137",
      explorer_urls: {
        tx: "https://polygonscan.com/tx/{}",
        address: "https://polygonscan.com/address/{}",
        token: "https://polygonscan.com/token/{}",
      },
    },
    {
      name: "chains/OAS",
      network: "mainnet",
      symbol: "OAS",
      description: "Oasys",
      chain_id: "248",
      explorer_urls: {
        tx: "https://explorer.oasys.games/tx/{}",
        address: "https://explorer.oasys.games/address/{}",
        token: "https://explorer.oasys.games/token/{}",
      },
    },
    {
      name: "chains/OptETH",
      network: "mainnet",
      symbol: "OptETH",
      description: "Optimism",
      chain_id: "10",
      explorer_urls: {
        tx: "https://optimistic.etherscan.io/tx/{}",
        address: "https://optimistic.etherscan.io/address/{}",
        token: "https://optimistic.etherscan.io/token/{}",
      },
    },
    {
      name: "chains/SEI",
      network: "mainnet",
      symbol: "SEI",
      description: "Sei",
      chain_id: "pacific-1",
      explorer_urls: {
        tx: "https://www.seiscan.app/pacific-1/txs/{}",
        address: "https://www.seiscan.app/pacific-1/accounts/{}",
      },
    },
    {
      name: "chains/SOL",
      network: "mainnet",
      symbol: "SOL",
      description: "Solana",
      explorer_urls: {
        tx: "https://solscan.io/tx/{}",
        address: "https://solscan.io/account/{}",
        token: "https://solscan.io/account/{}",
      },
    },
    {
      name: "chains/SUI",
      network: "mainnet",
      symbol: "SUI",
      description: "Sui",
      explorer_urls: {
        tx: "https://suiscan.xyz/mainnet/tx/{}",
        address: "https://suiscan.xyz/mainnet/account/{}",
        token: "https://suiscan.xyz/mainnet/coin/{}",
      },
    },
    {
      name: "chains/TAO",
      network: "mainnet",
      symbol: "TAO",
      description: "Bittensor",
      explorer_urls: {
        tx: "https://x.taostats.io/extrinsic/{}",
        address: "https://x.taostats.io/account/{}",
      },
    },
    {
      name: "chains/TIA",
      network: "mainnet",
      symbol: "TIA",
      description: "Celestia",
      chain_id: "celestia",
      explorer_urls: {
        tx: "https://celestia.explorers.guru/transaction/{}",
        address: "https://celestia.explorers.guru/account/{}",
      },
    },
    {
      name: "chains/TON",
      network: "mainnet",
      symbol: "TON",
      explorer_urls: {
        tx: "https://tonviewer.com/transaction/{}",
        address: "https://tonviewer.com/{}",
        token: "https://tonviewer.com/{}",
      },
    },
    {
      name: "chains/TRX",
      network: "mainnet",
      symbol: "TRX",
      description: "Tron",
      explorer_urls: {
        tx: "https://tronscan.org/#/transaction/{}",
        address: "https://tronscan.org/#/address/{}",
        token: "https://tronscan.org/#/contract/{}",
      },
    },
    {
      name: "chains/XDC",
      network: "mainnet",
      symbol: "XDC",
      description: "XinFin",
      chain_id: "50",
      explorer_urls: {
        tx: "https://explorer.xinfin.network/txs/{}",
        address: "https://explorer.xinfin.network/address/{}",
        token: "https://explorer.xinfin.network/tokens/{}",
      },
    },
    {
      name: "chains/XPLA",
      network: "mainnet",
      symbol: "XPLA",
      description: "XPLA Chain",
      chain_id: "dimension_37-1",
      explorer_urls: {
        tx: "https://explorer.xpla.io/mainnet/tx/{}",
        address: "https://explorer.xpla.io/mainnet/address/{}",
        token: "https://explorer.xpla.io/mainnet/token/{}",
      },
    },
    {
      name: "chains/XRP",
      network: "mainnet",
      symbol: "XRP",
      description: "xrp",
      explorer_urls: {
        tx: "https://livenet.xrpl.org/transactions/{}",
        address: "https://livenet.xrpl.org/accounts/{}",
      },
    },
  ],
};

const testnetFallbackUrls: ChainList = {
  chains: [
    {
      name: "chains/APTOS",
      network: "devnet",
      symbol: "APTOS",
      description: "Aptos (Devnet)",
      explorer_urls: {
        tx: "https://aptoscan.com/transaction/{}?network=devnet",
        address: "https://aptoscan.com/account/{}?network=devnet",
        token: "https://aptoscan.com/coin/{}?network=devnet",
      },
    },
    {
      name: "chains/ATOM",
      network: "testnet",
      symbol: "ATOM",
      description: "Cosmos (Theta Testnet)",
      chain_id: "theta-testnet-001",
      explorer_urls: {},
    },
    {
      name: "chains/AVAX",
      network: "testnet",
      symbol: "AVAX",
      description: "Avalanche (Fuji Testnet)",
      chain_id: "43113",
      explorer_urls: {
        tx: "https://testnet.snowtrace.io/tx/{}",
        address: "https://testnet.snowtrace.io/address/{}",
        token: "https://testnet.snowtrace.io/token/{}",
      },
    },
    {
      name: "chains/BERA",
      network: "bartio",
      symbol: "BERA",
      description: "Berachain",
      explorer_urls: {
        tx: "https://bartio.beratrail.com/tx/{}",
        address: "https://bartio.beratrail.com/address/{}",
        token: "https://bartio.beratrail.com/token/{}",
      },
    },
    {
      name: "chains/BNB",
      network: "testnet",
      symbol: "BNB",
      description: "Binance Smart Chain (Testnet)",
      chain_id: "97",
      explorer_urls: {
        tx: "https://testnet.bscscan.com/tx/{}",
        address: "https://testnet.bscscan.com/address/{}",
        token: "https://testnet.bscscan.com/token/{}",
      },
    },
    {
      name: "chains/BTC",
      network: "testnet",
      symbol: "BTC",
      description: "Bitcoin (Testnet)",
      explorer_urls: {
        tx: "https://mempool.space/testnet/tx/{}",
        address: "https://mempool.space/testnet/address/{}",
      },
    },
    {
      name: "chains/CELO",
      network: "testnet",
      symbol: "CELO",
      description: "Celo (Testnet)",
      chain_id: "44787",
      explorer_urls: {
        tx: "https://alfajores.celoscan.io/tx/{}",
        address: "https://alfajores.celoscan.io/address/{}",
        token: "https://alfajores.celoscan.io/token/{}",
      },
    },
    {
      name: "chains/CHZ2",
      network: "testnet",
      symbol: "CHZ2",
      description: "Chiliz 2.0 (testnet)",
      chain_id: "88882",
      explorer_urls: {
        tx: "https://testnet.chiliscan.com/tx/{}",
        address: "https://testnet.chiliscan.com/address/{}",
        token: "https://testnet.chiliscan.com/token/{}",
      },
    },
    {
      name: "chains/DOT",
      network: "testnet",
      symbol: "DOT",
      description: "Rococo",
      chain_id: "1001",
      explorer_urls: {
        tx: "https://rococo.subscan.io/extrinsic/{}",
        address: "https://rococo.subscan.io/account/{}",
      },
    },
    {
      name: "chains/ETH",
      network: "holesky",
      symbol: "ETH",
      description: "Ethereum (Holesky)",
      explorer_urls: {
        tx: "https://holesky.etherscan.io/tx/{}",
        address: "https://holesky.etherscan.io/address/{}",
        token: "https://holesky.etherscan.io/token/{}",
      },
    },
    {
      name: "chains/EmROSE",
      network: "testnet",
      symbol: "EmROSE",
      description: "Oasis Emerald (Testnet)",
      chain_id: "42261",
      explorer_urls: {
        tx: "https://explorer.oasis.io/testnet/emerald/tx/{}",
        address: "https://explorer.oasis.io/testnet/emerald/address/{}",
        token: "https://explorer.oasis.io/testnet/emerald/token/{}",
      },
    },
    {
      name: "chains/FTM",
      network: "testnet",
      symbol: "FTM",
      description: "Fantom (Testnet)",
      chain_id: "4002",
      explorer_urls: {
        tx: "https://testnet.ftmscan.com/tx/{}",
        address: "https://testnet.ftmscan.com/address/{}",
        token: "https://testnet.ftmscan.com/token/{}",
      },
    },
    {
      name: "chains/HASH",
      network: "testnet",
      symbol: "HASH",
      description: "Provenance (Testnet)",
      chain_id: "pio-testnet-1",
      explorer_urls: {},
    },
    {
      name: "chains/INJ",
      network: "testnet",
      symbol: "INJ",
      description: "Injective (Testnet)",
      chain_id: "injective-888",
      explorer_urls: {
        tx: "https://testnet.explorer.injective.network/transaction/{}",
        address: "https://testnet.explorer.injective.network/account/{}",
        token: "https://testnet.explorer.injective.network/asset/?denom={}",
      },
    },
    {
      name: "chains/KAR",
      network: "testnet",
      symbol: "KAR",
      description: "Karura (Testnet)",
      chain_id: "686",
      explorer_urls: {},
    },
    {
      name: "chains/KLAY",
      network: "testnet",
      symbol: "KLAY",
      description: "Klaytn (Baobab Testnet)",
      chain_id: "1001",
      explorer_urls: {
        tx: "https://baobab.klaytnscope.com/tx/{}",
        address: "https://baobab.klaytnscope.com/account/{}",
        token: "https://baobab.klaytnscope.com/token/{}",
      },
    },
    {
      name: "chains/LUNA",
      network: "testnet",
      symbol: "LUNA",
      description: "Terra (Testnet)",
      chain_id: "pisco-1",
      explorer_urls: {
        tx: "https://finder.terra.money/testnet/tx/{}",
        address: "https://finder.terra.money/testnet/address/{}",
        token: "https://finder.terra.money/testnet/address/{}",
      },
    },
    {
      name: "chains/MATIC",
      network: "testnet",
      symbol: "MATIC",
      description: "Polygon (Mumbai)",
      chain_id: "80001",
      explorer_urls: {
        tx: "https://amoy.polygonscan.com/tx/{}",
        address: "https://amoy.polygonscan.com/address/{}",
        token: "https://amoy.polygonscan.com/token/{}",
      },
    },
    {
      name: "chains/SEI",
      network: "atlantic-2",
      symbol: "SEI",
      description: "Sei",
      chain_id: "atlantic-2",
      explorer_urls: {
        tx: "https://www.seiscan.app/atlantic-2/txs/{}",
        address: "https://www.seiscan.app/atlantic-2/accounts/{}",
      },
    },
    {
      name: "chains/SOL",
      network: "devnet",
      symbol: "SOL",
      description: "Solana (Devnet)",
      explorer_urls: {
        tx: "https://explorer.solana.com/tx/{}?cluster=devnet",
        address: "https://explorer.solana.com/address/{}?cluster=devnet",
        token: "https://explorer.solana.com/address/{}?cluster=devnet",
      },
    },
    {
      name: "chains/SUI",
      network: "devnet",
      symbol: "SUI",
      description: "Sui (Devnet)",
      explorer_urls: {
        tx: "https://suiscan.xyz/devnet/tx/{}",
        address: "https://suiscan.xyz/devnet/account/{}",
        token: "https://suiscan.xyz/devnet/coin/{}",
      },
    },
    {
      name: "chains/TIA",
      network: "mocha-4",
      symbol: "TIA",
      description: "Celestia",
      chain_id: "mocha-4",
      explorer_urls: {
        tx: "https://testnet.celestia.explorers.guru/transaction/{}",
        address: "https://testnet.celestia.explorers.guru/account/{}",
      },
    },
    {
      name: "chains/TON",
      network: "testnet",
      symbol: "TON",
      explorer_urls: {
        tx: "https://testnet.tonviewer.com/transaction/{}",
        address: "https://testnet.tonviewer.com/{}",
        token: "https://testnet.tonviewer.com/{}",
      },
    },
    {
      name: "chains/TRX",
      network: "testnet",
      symbol: "TRX",
      description: "Nile",
      explorer_urls: {
        tx: "https://nile.tronscan.org/#/transaction/{}",
        address: "https://nile.tronscan.org/#/address/{}",
        token: "https://nile.tronscan.org/#/contract/{}",
      },
    },
    {
      name: "chains/XPLA",
      network: "testnet",
      symbol: "XPLA",
      description: "XPLA (Testnet)",
      chain_id: "cube_47-5",
      explorer_urls: {
        tx: "https://explorer.xpla.io/testnet/tx/{}",
        address: "https://explorer.xpla.io/testnet/address/{}",
        token: "https://explorer.xpla.io/testnet/token/{}",
      },
    },
    {
      name: "chains/XRP",
      network: "devnet",
      symbol: "XRP",
      description: "xrp (Devnet)",
      explorer_urls: {
        tx: "https://testnet.xrpl.org/transactions/{}",
        address: "https://testnet.xrpl.org/accounts/{}",
      },
    },
  ],
};
