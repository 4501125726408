import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Result } from "../../../lib/api/api";
import { Asset } from "../../../lib/api/types";

export interface DeleteAssetFormData {
  name: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: DeleteAssetFormData): Promise<Result<Asset>> {
  return await httpClientInstance.deleteAsset([formData.name]);
}

export function useDeleteAsset(): UseMutationResult<
  Result<Asset>,
  SubmitError,
  DeleteAssetFormData
> {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<Result<Asset>, SubmitError, DeleteAssetFormData> = {
    mutationFn: submitFormData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["AssetsPage"] });
      console.log("Asset deleted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error deleting asset:", error.message);
    },
  };

  return useMutation(mutationOptions);
}
