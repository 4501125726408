import { KEY_API } from "lib/constants";
import { HttpClient } from "../lib/api/api";
import { ParseName } from "../lib/api/name";
import { WebauthnClient } from "../lib/api/webauthn";
//import { Logger } from "../utils/logger"; // make a real logger instead of console.log

function initializeWebauthnClient(): WebauthnClient {
  let rpID;
  if (window.location.host.includes("localhost")) {
    rpID = "localhost";
  } else {
    if (window.FIDO_RP_ID && !window.FIDO_RP_ID.includes("localhost")) {
      rpID = window.FIDO_RP_ID;
      // Sanity check
      if (!window.location.host.includes(rpID)) {
        console.warn(
          `Configured RpID is not a subset of current host (have ${rpID} but current host is ${window.location.host}).\n` +
            "This may cause the browser to any reject WebAuthn requests.",
        );
      }
    } else {
      // default to using the window host
      rpID = window.location.host;
    }
  }
  console.log("rpID", rpID);
  try {
    console.log("initializing webauthn client");
    const webauthnClient = new WebauthnClient({
      // it may be better if we can first query for treasury ID and just
      // pass it here, rather than need to set later.
      treasuryId: "",
      RpID: rpID,
      onSignStart: () => Logger.info("requested webauthn signature"),
      onSignEnd: () => Logger.info("got webauthn signature"),
    });

    return webauthnClient;
  } catch (error) {
    Logger.error("Error initializing WebauthnClient", error);
    throw error;
  }
}

function createHttpClientInstance(): HttpClient {
  try {
    const webauthnClient = initializeWebauthnClient();

    let initialApiUrl = localStorage.getItem(KEY_API);
    console.log("initialApiUrl", initialApiUrl);

    if (!initialApiUrl) {
      console.log("initialApiUrl not found");
      initialApiUrl = window.API_URL || "";
      if (!initialApiUrl) {
        // localStorage.setItem("initialApiUrl");
        throw new Error("API URL not found");
      }
      console.log("setting initial url to", initialApiUrl);
    }

    const httpClientInstance = new HttpClient(initialApiUrl, {
      webauthnClient: webauthnClient,
      version: 1,
    });
    httpClientInstance
      .list("Treasury") // should only be one as treasury resource is a singleton
      .then((res) => {
        const name = ParseName(res.treasuries![0].name!, "Treasury").resourceId;
        console.log("treasury name:", name);
        webauthnClient.setTreasuryId(name);
      })
      .catch((e) => {
        console.error("could not set treasury ID: ", e);
      });

    return httpClientInstance;
  } catch (error) {
    Logger.error("Error initializing HttpClient", error);
    throw error;
  }
}

export const httpClientInstance = createHttpClientInstance();

//quick hack to get rid of console.log

class Logger {
  static info(message: string, ...optionalParams: any[]) {
    console.info(`INFO: ${message}`, ...optionalParams);
  }

  static error(message: string, ...optionalParams: any[]) {
    console.error(`ERROR: ${message}`, ...optionalParams);
  }

  static debug(message: string, ...optionalParams: any[]) {
    if (process.env.NODE_ENV === "development") {
      console.debug(`DEBUG: ${message}`, ...optionalParams);
    }
  }
}
