import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Result } from "../../../lib/api/api";
import { Address } from "../../../lib/api/types";

export interface DeleteAddressFormData {
  name: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: DeleteAddressFormData) {
  return await httpClientInstance.deleteAddress([formData.name]);
}

export function useDeleteAddress(): UseMutationResult<
  Result<Address>,
  SubmitError,
  DeleteAddressFormData
> {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<Result<Address>, SubmitError, DeleteAddressFormData> = {
    mutationFn: submitFormData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["address"] });
      console.log("Address deleted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error deleting Address:", error.message);
    },
  };

  return useMutation(mutationOptions);
}
