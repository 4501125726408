import React, { useState } from "react";
import { ParseName } from "../../../../lib/api/name";

interface SelectItem {
  label: string;
  value: string;
}

interface PreviewSummaryProps {
  values: {
    policyDescription: string;
    permissionType: string;
    numberOfApprovers: number;
  };
  accountAndAddressModeFrom: string;
  selectedAccountsFrom: SelectItem[];
  selectedAddressesFrom: SelectItem[];
  accountAndAddressModeTo: string;
  selectedAccountsTo: SelectItem[];
  selectedAddressesTo: SelectItem[];
  mode: string;
  selectedChains: SelectItem[];
  selectedAssets: SelectItem[];
  viewMode: string;
  selectedGroups: SelectItem[];
  filteredUsers: SelectItem[];
  limits: boolean;
  atLeast?: string;
  moreThan?: string;
  atMost?: string;
  lessThan?: string;
  within?: string;
  withinPeriod?: string;
}

const COLORS = {
  BORDER: "#ccc",
  LIGHT_BLUE: "#ADD8E6",
  LIGHT_RED: "#FFCCCB",
  LIGHT_ORANGE: "#FFD580",
  LIGHT_GRAY: "#D3D3D3",
  LIGHT_PINK: "#FFB6C1",
  LAVENDER: "#E6E6FA",
  MOCCASIN: "#FFE4B5",
  LIGHT_GOLDENROD_YELLOW: "#FAFAD2",
  LEMON_CHIFFON: "#FFFACD",
  WHITE: "#FFFFFF",
};

const PreviewSummary: React.FC<PreviewSummaryProps> = ({
  values,
  accountAndAddressModeFrom,
  selectedAccountsFrom,
  selectedAddressesFrom,
  accountAndAddressModeTo,
  selectedAccountsTo,
  selectedAddressesTo,
  mode,
  selectedChains,
  selectedAssets,
  viewMode,
  selectedGroups,
  filteredUsers,
  limits,
  atLeast,
  moreThan,
  atMost,
  lessThan,
  within,
  withinPeriod,
}) => {
  const [showAllFrom, setShowAllFrom] = useState(false);
  const [showAllTo, setShowAllTo] = useState(false);
  const [showAllChainsAssets, setShowAllChainsAssets] = useState(false);
  const [showAllGroupsUsers, setShowAllGroupsUsers] = useState(false);

  const renderItemsWithMore = (
    items: SelectItem[],
    showAll: boolean,
    setShowAll: React.Dispatch<React.SetStateAction<boolean>>,
    backgroundColor: string,
    parseFunc?: (item: SelectItem) => string,
  ) => {
    const displayedItems = showAll ? items : items.slice(0, 5);
    const itemElements = displayedItems.map((item, index) => (
      <span
        key={index}
        style={{
          border: `1px solid ${COLORS.BORDER}`,
          borderRadius: "25px",
          padding: "5px 10px",
          backgroundColor,
          color: "black",
          margin: "10px 0",
          lineHeight: "1.5",
        }}
      >
        {parseFunc ? parseFunc(item) : item.label}
      </span>
    ));

    if (items.length > 5) {
      itemElements.push(
        <span
          key="toggle"
          style={{
            border: `1px solid ${COLORS.BORDER}`,
            borderRadius: "25px",
            padding: "5px 10px",
            backgroundColor: COLORS.WHITE,
            color: "black",
            margin: "5px 0",
            cursor: "pointer",
          }}
          onClick={() => setShowAll(!showAll)}
          role="button"
          tabIndex={0}
          onKeyDown={(event) => {
            if (event.key === "Enter" || event.key === " ") {
              setShowAll(!showAll);
            }
          }}
        >
          {showAll ? "Less..." : "More..."}
        </span>,
      );
    }

    return itemElements;
  };

  const getPermissionTypeColor = (type: string) => {
    switch (type) {
      case "allow":
        return COLORS.LIGHT_BLUE;
      case "deny":
        return COLORS.LIGHT_RED;
      default:
        return COLORS.LIGHT_ORANGE;
    }
  };

  return (
    <div
      style={{
        width: "100%",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "400px",
      }}
    >
      <div>
        <p style={{ lineHeight: "2.5" }}>
          Your Policy {values.policyDescription} will{" "}
          <span
            style={{
              border: `1px solid ${COLORS.BORDER}`,
              borderRadius: "25px",
              padding: "5px 10px",
              backgroundColor: getPermissionTypeColor(values.permissionType),
              color: "black",
              margin: "0 10px",
            }}
          >
            {values.permissionType}
          </span>{" "}
          transactions from:{" "}
          {accountAndAddressModeFrom === "all" ? (
            <span
              style={{
                border: `1px solid ${COLORS.BORDER}`,
                borderRadius: "25px",
                padding: "5px 10px",
                backgroundColor: COLORS.LIGHT_GRAY,
                color: "black",
                margin: "0 10px",
              }}
            >
              all accounts and addresses
            </span>
          ) : accountAndAddressModeFrom === "account" ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                selectedAccountsFrom,
                showAllFrom,
                setShowAllFrom,
                COLORS.LIGHT_PINK,
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                selectedAddressesFrom,
                showAllFrom,
                setShowAllFrom,
                COLORS.LAVENDER,
                (address) => ParseName(address.label, "Address").resourceId,
              )}
            </div>
          )}{" "}
          to:{" "}
          {accountAndAddressModeTo === "all" ? (
            <span
              style={{
                border: `1px solid ${COLORS.BORDER}`,
                borderRadius: "25px",
                padding: "5px 10px",
                backgroundColor: COLORS.LIGHT_GRAY,
                color: "black",
                margin: "0 10px",
              }}
            >
              all accounts and addresses
            </span>
          ) : accountAndAddressModeTo === "account" ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(selectedAccountsTo, showAllTo, setShowAllTo, COLORS.LIGHT_PINK)}
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                selectedAddressesTo,
                showAllTo,
                setShowAllTo,
                COLORS.MOCCASIN,
                (address) => ParseName(address.label, "Address").resourceId,
              )}
            </div>
          )}{" "}
          for{" "}
          {mode === "all" ? (
            <span
              style={{
                border: `1px solid ${COLORS.BORDER}`,
                borderRadius: "25px",
                padding: "5px 10px",
                backgroundColor: COLORS.LIGHT_GRAY,
                color: "black",
                margin: "0 10px",
              }}
            >
              all chains and assets
            </span>
          ) : mode === "chain" ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                selectedChains,
                showAllChainsAssets,
                setShowAllChainsAssets,
                COLORS.LAVENDER,
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                selectedAssets,
                showAllChainsAssets,
                setShowAllChainsAssets,
                COLORS.MOCCASIN,
              )}
            </div>
          )}{" "}
          for{" "}
          {viewMode === "none" || viewMode === "all" ? (
            <span
              style={{
                border: `1px solid ${COLORS.BORDER}`,
                borderRadius: "25px",
                padding: "5px 10px",
                backgroundColor: COLORS.LIGHT_GRAY,
                color: "black",
                margin: "0 10px",
              }}
            >
              no users or groups
            </span>
          ) : viewMode === "group" ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                selectedGroups,
                showAllGroupsUsers,
                setShowAllGroupsUsers,
                COLORS.LIGHT_GOLDENROD_YELLOW,
              )}
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {renderItemsWithMore(
                filteredUsers,
                showAllGroupsUsers,
                setShowAllGroupsUsers,
                COLORS.LIGHT_GOLDENROD_YELLOW,
              )}
            </div>
          )}
          {values.numberOfApprovers > 0 && (
            <>
              {" "}
              with{" "}
              <span
                style={{
                  border: `1px solid ${COLORS.BORDER}`,
                  borderRadius: "25px",
                  padding: "5px 10px",
                  backgroundColor: COLORS.LEMON_CHIFFON,
                  color: "black",
                  margin: "0 10px",
                }}
              >
                {values.numberOfApprovers}
              </span>{" "}
              approver
              {values.numberOfApprovers > 1 ? "s" : ""}.
            </>
          )}
          {limits && (atLeast || moreThan || atMost || lessThan) && (
            <>
              {" "}
              with{" "}
              <span
                style={{
                  border: `1px solid ${COLORS.BORDER}`,
                  borderRadius: "25px",
                  padding: "5px 10px",
                  backgroundColor: COLORS.LIGHT_GRAY,
                  color: "black",
                  margin: "0 10px",
                }}
              >
                {atLeast && `${atLeast ?? 0} ≤ `}
                {moreThan && `${moreThan ?? 0} < `}
                amount {atMost && `≤ ${atMost ?? 0} `}
                {lessThan && `< ${lessThan ?? 0}`}
              </span>{" "}
              {within && within !== "0" && (
                <>
                  within{" "}
                  <span
                    style={{
                      border: `1px solid ${COLORS.BORDER}`,
                      borderRadius: "25px",
                      padding: "5px 10px",
                      backgroundColor: COLORS.LIGHT_GRAY,
                      color: "black",
                      margin: "0 10px",
                    }}
                  >
                    {within} {withinPeriod}
                  </span>{" "}
                </>
              )}
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default PreviewSummary;
