import { useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

async function deleteTransferRule(name: string) {
  return await httpClientInstance.deleteTransferRule(name);
}

export function useDeleteRule() {
  const mutation = useMutation({
    mutationFn: deleteTransferRule,
  });

  return mutation;
}
