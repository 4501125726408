import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface TransferFormData {
  name: string;
  asset: string;
  amount: string;
  from: string;
  to: string;
  labels: any;
  notes: any;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: TransferFormData) {
  console.log("submitting Transfer form");
  console.log("submitting Transfer form", formData);
  return await httpClientInstance.createTransfer(formData.name, {
    asset: formData.asset,
    amount: formData.amount,
    from: formData.from,
    to: formData.to,
  });
}

export function useTransferMutation(): UseMutationResult<
  Awaited<ReturnType<typeof submitFormData>>,
  SubmitError,
  TransferFormData
> {
  return useMutation<Awaited<ReturnType<typeof submitFormData>>, SubmitError, TransferFormData>({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Transfer Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting Transfer form:", error.message);
    },
  });
}
