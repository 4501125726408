import {
  Button,
  Modal,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import { Autoscaling } from "@carbon/react/icons";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CPage from "../../components/CPage";
import { ParseName } from "../../lib/api/name";
import { commonNotificationsBuilders, useNotificationStore } from "../../stores/notifications";
import { getExplorerUrl } from "../../utils/BlockchainExplorerManager";
import { useDeleteAsset } from "./api/deleteAsset";
import { useAsset } from "./api/getAsset";
import AssetEditForm from "./components/AssetEditForm";

const AssetDetail: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { addNotification } = useNotificationStore();

  const params = useParams<{ "*": string }>();
  const name = "/" + params["*"]!;

  const { data: asset, isLoading, error } = useAsset({ assetId: name });

  const { mutate: deleteAsset, isPaused: isDeleting } = useDeleteAsset();

  const handleDelete = () => {
    deleteAsset(
      { name },
      {
        onSuccess: (res) => {
          if (res.operation.state === "authorizing") {
            const notifDetails = commonNotificationsBuilders.authorizing(
              res.operation.name?.split("/")[1],
            );
            return addNotification({
              type: notifDetails.type,
              title: notifDetails.title,
              message: notifDetails.message,
            });
          }

          navigate("/assets");
          addNotification({
            type: "success",
            title: "Asset deleted successfully",
          });
        },
        onError: (error) => {
          addNotification({
            type: "error",
            title: "Error deleting asset",
            message: error.message,
          });
          console.error("Error deleting asset:", error.message);
        },
      },
    );
  };

  function navigateToBlockChainExplorer(e: React.MouseEvent | React.KeyboardEvent) {
    e.stopPropagation();

    const url = getExplorerUrl(
      ParseName([asset?.name ?? ""], "Asset").parentId ?? "",
      "token",
      asset?.contract ?? "",
    );

    window.open(url, "_blank", "noopener,noreferrer");
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading asset details: {error.message}</div>;
  }

  if (!asset) {
    return <div>No asset details available</div>;
  }

  return (
    <CPage title="Asset Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/assets")}>
          Back to Assets
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" onClick={() => setIsEditModalOpen(true)}>
            Edit
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>
      <StructuredListWrapper>
        <StructuredListHead>{/* Optionally add header content here */}</StructuredListHead>
        <StructuredListBody>
          <StructuredListRow>
            <StructuredListCell head>Chain</StructuredListCell>
            <StructuredListCell>
              {asset.name && ParseName(asset.name, "Asset").parentId}
            </StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Description</StructuredListCell>
            <StructuredListCell>{asset.notes?.description}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Variant</StructuredListCell>
            <StructuredListCell>{asset.variant}</StructuredListCell>
          </StructuredListRow>
          <StructuredListRow>
            <StructuredListCell head>Decimals</StructuredListCell>
            <StructuredListCell>{asset.decimals}</StructuredListCell>
          </StructuredListRow>
          {asset.symbol && (
            <StructuredListRow>
              <StructuredListCell head>Symbol</StructuredListCell>
              <StructuredListCell>
                <div style={{ display: "flex", alignItems: "left", gap: "10px" }}>
                  <span>{ParseName([asset.symbol], "Symbol").resourceId}</span>
                  <span
                    onClick={navigateToBlockChainExplorer}
                    onKeyDown={navigateToBlockChainExplorer}
                    role="button"
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                    title="Navigate to explorer"
                  >
                    <Autoscaling />
                  </span>
                </div>
              </StructuredListCell>
            </StructuredListRow>
          )}
          <StructuredListRow>
            <StructuredListCell head>Contract</StructuredListCell>
            <StructuredListCell>
              <div style={{ display: "flex", alignItems: "left", gap: "10px" }}>
                <span>{asset.contract}</span>
                <span
                  onClick={navigateToBlockChainExplorer}
                  onKeyDown={navigateToBlockChainExplorer}
                  role="button"
                  tabIndex={0}
                  style={{ cursor: "pointer" }}
                  title="Navigate to explorer"
                >
                  <Autoscaling />
                </span>
              </div>
            </StructuredListCell>
          </StructuredListRow>
        </StructuredListBody>
      </StructuredListWrapper>

      <Modal
        open={isModalOpen}
        modalHeading="Confirm Delete"
        primaryButtonText={isDeleting ? "Deleting..." : "Delete"}
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
        primaryButtonDisabled={isDeleting}
      >
        <p>Are you sure you want to delete: {asset.name}?</p>
      </Modal>

      <Modal
        open={isEditModalOpen}
        modalHeading="Edit Asset"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsEditModalOpen(false)}
        onRequestSubmit={() => {
          // Trigger form submission programmatically
          document
            .getElementById("asset-edit-form")
            ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }}
      >
        <AssetEditForm assetId={name} onClose={() => setIsEditModalOpen(false)} />
      </Modal>
    </CPage>
  );
};

export default AssetDetail;
