import { useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Transfer } from "../../../lib/api/types";

async function abortTransfer(id: string): Promise<Transfer> {
  console.log("Transfer abort called");
  const result = await httpClientInstance.abortTransfer(id);
  if (result.success()) {
    return result.getResource();
  } else {
    throw new Error("Abort transfer failed");
  }
}

export function useAbortTransfer() {
  const mutation = useMutation<Transfer, Error, string>({
    mutationFn: abortTransfer,
    onSuccess: () => {
      console.log("Transfer aborted");
    },
  });

  return mutation;
}
