import { Account } from "@/lib/api/types";
import { useMutation, UseMutationOptions, UseMutationResult } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface UpdateAccountFormData {
  name: string;
  accountData: Account;
  availableAddresses: string[];
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateAccountFormData) {
  return await httpClientInstance.updateAccount(formData.name, {
    ...formData.accountData,
    addresses: [...formData.availableAddresses],
  });
}

export function useModifyAccountMutation(
  options?: UseMutationOptions<
    Awaited<ReturnType<typeof submitModifyFormData>>,
    SubmitError,
    UpdateAccountFormData
  >,
): UseMutationResult<
  Awaited<ReturnType<typeof submitModifyFormData>>,
  SubmitError,
  UpdateAccountFormData
> {
  return useMutation<
    Awaited<ReturnType<typeof submitModifyFormData>>,
    SubmitError,
    UpdateAccountFormData
  >({
    mutationFn: submitModifyFormData,
    onSuccess: (data, variables, context) => {
      console.log("Update Account Form modified successfully");

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateAccountFormData, context: unknown) => {
      console.error("Error submitting modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
