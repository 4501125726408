import { TextInput } from "@carbon/react";
import { Form, Formik, FormikHelpers } from "formik";
import React from "react";

import { MultiColumnRow } from "../../../components/form";
import { commonNotificationsBuilders, useNotificationStore } from "../../../stores/notifications";
import { useAsset } from "../api/getAsset";
import { UpdateAssetFormData, useModifyAssetMutation } from "../api/updateAsset";

interface AssetEditFormProps {
  assetId: string | undefined;
  onClose: () => void;
}

const AssetEditForm: React.FC<AssetEditFormProps> = ({ assetId, onClose }) => {
  const { mutate } = useModifyAssetMutation();
  const { addNotification } = useNotificationStore();

  const { data: asset, isLoading, error } = useAsset({ assetId: assetId ?? "" });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading asset details...</div>;
  }

  console.log("asset", asset);

  const initialValues: UpdateAssetFormData = {
    name: asset?.name || "",
    addressData: asset || {},
  };

  const onSubmit = async (
    values: UpdateAssetFormData,
    { setSubmitting }: FormikHelpers<UpdateAssetFormData>,
  ) => {
    try {
      mutate(values, {
        onError: (err: any) => {
          addNotification({ type: "error", title: "Error", message: `Error: ${err.message}` });
        },
        onSuccess: (res) => {
          if (res.operation.state === "authorizing") {
            const notifDetails = commonNotificationsBuilders.authorizing(
              res.operation.name?.split("/")[1],
            );
            addNotification({
              type: notifDetails.type,
              title: notifDetails.title,
              message: notifDetails.message,
            });
          } else
            addNotification({
              type: "success",
              title: "Success",
              message: "Asset updated successfully",
            });
          onClose();
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form id="asset-edit-form">
          <MultiColumnRow>
            <h5 style={{ paddingBottom: "15px" }}>{values.name || "NA"}</h5>
          </MultiColumnRow>

          {/* <MultiColumnRow>
            <TextInput
              id="variant"
              name="addressData.variant"
              labelText="Variant"
              value={values.addressData.variant || ""}
              onChange={(e) => setFieldValue("addressData.variant", e.target.value)}
            />
          </MultiColumnRow> */}

          <MultiColumnRow>
            <TextInput
              id="decimals"
              name="addressData.decimals"
              labelText="Decimals"
              value={values.addressData.decimals?.toString() || ""}
              onChange={(e) => setFieldValue("addressData.decimals", e.target.value)}
            />
          </MultiColumnRow>

          <MultiColumnRow>
            <TextInput
              id="description"
              name="addressData.notes.description"
              labelText="Description"
              value={values.addressData.notes?.description || ""}
              onChange={(e) => setFieldValue("addressData.notes.description", e.target.value)}
            />
          </MultiColumnRow>

          {/* <MultiColumnRow>
            <TextInput
              id="contract"
              name="addressData.contract"
              labelText="Contract"
              value={values.addressData.contract || ""}
              onChange={(e) => setFieldValue("addressData.contract", e.target.value)}
            />
          </MultiColumnRow> */}
        </Form>
      )}
    </Formik>
  );
};

export default AssetEditForm;
