import { Account, Address } from "@/lib/api/types";
import { FilterableMultiSelect, Tag, TextInput } from "@carbon/react";
import { Form, Formik, FormikHelpers } from "formik";
import React, { useEffect, useState } from "react";
import { MultiColumnRow } from "../../../components/form";
import { ParseName } from "../../../lib/api/name";
import { commonNotificationsBuilders, useNotificationStore } from "../../../stores/notifications";
import { useAccountPage } from "../api/getAccounts";
import { useAddressesPage } from "../api/getAddresses";
import { UpdateAccountFormData, useModifyAccountMutation } from "../api/updateAccount";

import { ItemBase } from "@carbon/react/lib/components/MultiSelect/MultiSelectPropTypes";

interface AccountEditFormProps {
  accountId: string | undefined;
  onClose: () => void;
}

const AccountEditForm: React.FC<AccountEditFormProps> = ({ accountId, onClose }) => {
  const { mutate } = useModifyAccountMutation();
  const { addNotification } = useNotificationStore();

  const {
    data: accountsPage,
    isLoading: isLoadingAccountsPage,
    error: errorAccountsPage,
  } = useAccountPage({ filter: `name="${accountId}"` });

  const {
    data: addressesPage,
    isLoading: isLoadingAddressesPage,
    error: errorAddressesPage,
  } = useAddressesPage();

  const [selectedAddresses, setSelectedAddresses] = useState<string[]>([]);
  const [availableAddresses, setAvailableAddresses] = useState<Address[]>([]);

  useEffect(() => {
    if (addressesPage && !isLoadingAddressesPage && !errorAddressesPage) {
      setAvailableAddresses(
        addressesPage.addresses?.filter((address) => !address.account) as Address[],
      );
    }
  }, [addressesPage, isLoadingAddressesPage, errorAddressesPage]);

  useEffect(() => {
    if (accountsPage?.accounts?.[0]) {
      const account = accountsPage.accounts[0] as Account;
      setSelectedAddresses(account.addresses || []);
    }
  }, [accountsPage]);

  if (isLoadingAccountsPage || isLoadingAddressesPage || !accountsPage?.accounts?.[0]) {
    return <div>Loading...</div>;
  }

  if (errorAddressesPage || errorAccountsPage) {
    return <div>Error loading...</div>;
  }

  const account: Account = accountsPage.accounts[0];

  const initialValues: UpdateAccountFormData = {
    name: account.name || "",
    accountData: account,
    availableAddresses: selectedAddresses,
  };

  const onSubmit = async (
    values: UpdateAccountFormData,
    { setSubmitting }: FormikHelpers<UpdateAccountFormData>,
  ) => {
    try {
      await mutate(values, {
        onError: (err: any) => {
          addNotification({ type: "error", title: "Error", message: `Error: ${err.message}` });
        },
        onSuccess: (res) => {
          if (res.operation.state === "authorizing") {
            const notifDetails = commonNotificationsBuilders.authorizing(
              res.operation.name?.split("/")[1],
            );
            addNotification({
              type: notifDetails.type,
              title: notifDetails.title,
              message: notifDetails.message,
            });
          } else
            addNotification({
              type: "success",
              title: "Success",
              message: "Account updated successfully",
            });
          onClose();
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  const removeAfterPipeAndSpaces = (input: string): string => {
    const pipeIndex = input.indexOf("|");
    return pipeIndex !== -1 ? input.substring(0, pipeIndex).trim() : input.trim();
  };

  const handleAddAddress = (address: string, setFieldValue: any) => {
    console.log("address", address);

    address = removeAfterPipeAndSpaces(address);
    if (!selectedAddresses.includes(address)) {
      const updatedAddresses = [...selectedAddresses, address];
      setSelectedAddresses(updatedAddresses);
      setFieldValue("availableAddresses", updatedAddresses);
      setAvailableAddresses(availableAddresses.filter((addr) => addr.name !== address));
    }
  };

  const handleRemoveAddress = (address: string, setFieldValue: any) => {
    console.log("address", address);
    const updatedAddresses = selectedAddresses.filter((item) => item !== address);
    setSelectedAddresses(updatedAddresses);
    setFieldValue("availableAddresses", updatedAddresses);
    const removedAddress = addressesPage?.addresses?.find((addr) => addr.name === address);
    if (removedAddress) {
      setAvailableAddresses([...availableAddresses, removedAddress]);
    }
  };

  const multiSelectItems: ItemBase[] = availableAddresses.map(
    (address) =>
      ({
        id: address.name,
        label: `${ParseName(address.name!, "Address").resourceId} | ${address.notes?.description || ""}`,
      }) as ItemBase,
  );

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form id="account-edit-form">
          <MultiColumnRow>
            <h5 style={{ paddingBottom: "15px" }}>
              {ParseName([values.name] ?? "", "Account").resourceId || "NA"}
            </h5>
          </MultiColumnRow>

          <MultiColumnRow>
            <TextInput
              id="description"
              name="accountData.notes.description"
              labelText="Description"
              value={values.accountData.notes?.description || ""}
              onChange={(e) => setFieldValue("accountData.notes.description", e.target.value)}
            />
          </MultiColumnRow>

          <div style={{ fontSize: "12px" }}>Current Addresses</div>
          <div style={{ padding: "10px 0", display: "flex", flexWrap: "wrap", gap: "10px" }}>
            {selectedAddresses.map((address) => (
              <Tag
                key={address}
                type="gray"
                filter
                onClose={() => handleRemoveAddress(address, setFieldValue)}
              >
                {ParseName(address, "Address").resourceId}
              </Tag>
            ))}
          </div>

          <div style={{ display: "flex" }}>
            <div style={{ flex: 1, marginRight: "190px", minHeight: "200px" }}>
              <FilterableMultiSelect
                id="multi-select-available-addresses"
                titleText="Available Addresses"
                items={multiSelectItems}
                // @ts-expect-error error
                itemToString={(item) => (item ? item.label : "")}
                onChange={({ selectedItems }) => {
                  selectedItems?.forEach((item) => {
                    // @ts-expect-error error
                    if (!selectedAddresses.includes(item.id)) {
                      // @ts-expect-error error
                      handleAddAddress(item.id, setFieldValue);
                    }
                  });
                }}
                // @ts-expect-error error
                label={""}
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccountEditForm;
