import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import { Checkmark, SubtractAlt } from "@carbon/react/icons";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CButton from "../../../components/CButton";
import { formatElapsedTime } from "../../../utils/formater";
import {
  useApproveOperation,
  UseApproveOperationFormData,
  useCancelOperation,
} from "../api/createApproveCancelApprovals";

import CustomPagination from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";
import { ParseName } from "../../../lib/api/name";
import { useApprovals } from "../api/getApprovals";
import JsonRenderer from "./JsonRenderer";

type Variant = "internal" | "external";
type State = "active" | "deleted";

export interface AddressListItem {
  account?: string;
  name?: string;
  variant?: Variant;
  state?: State;
  create_time?: string;
}

const ApprovalsIndexTable: React.FC = () => {
  const { data: operationsPage } = useApprovals();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { mutate: approveMutate } = useApproveOperation();
  const { mutate: cancelMutate } = useCancelOperation();
  const navigate = useNavigate();

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPage, //
  } = usePagination();

  const operations = operationsPage?.operations ?? [];

  const headers = [
    { key: "create_time", header: "Time Since" },
    { key: "action", header: "Action Details" },
    { key: "name", header: "Operation" },
    { key: "approvers", header: "Approvers" },
    { key: "button", header: "" },
  ];
  const mappedRows = operations.map((item, index) => {
    const initiatorName = ParseName(item.initiator, "User").resourceId || "NA";
    const actionText = `${initiatorName} performed the action ${item.request?.action} on resource ${item.request?.resource} with ID ${item.request?.id}`;

    return {
      id: `row-${index}`,
      state: item.state,
      create_time: formatElapsedTime(item.create_time ?? "") || item.create_time,
      approvers: item.approve?.join(", ") ?? "",
      body: item.request?.body || "",
      name: ParseName(item.name ?? "", "Operation").resourceId || "NA",
      action: (
        <span>
          <strong>{initiatorName}</strong> performed the action{" "}
          <strong>{item.request?.action}</strong> on resource
          {item.request?.resource ? (
            <>
              {" "}
              of type <strong>{item.request.resource}</strong>
            </>
          ) : (
            ""
          )}{" "}
          with ID <strong>{item.request?.id}</strong>.
        </span>
      ),
      button: item,
      searchableText: `
        ${item.state}
        ${item.create_time}
        ${item.request?.action}
        ${item.request?.resource}
        ${item.request?.id}
        ${JSON.stringify(item.request?.body)}
        ${initiatorName}
        ${actionText}
      `.toLowerCase(),
    };
  });

  const filteredRows = mappedRows.filter(
    (row) => row.state === "authorizing" && row.searchableText.includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  const handleApprove = (values: any, event: React.MouseEvent) => {
    event.stopPropagation();
    const formData: UseApproveOperationFormData = {
      operation: values,
    };
    approveMutate(formData);
  };

  const handleCancel = (values: any, event: React.MouseEvent) => {
    event.stopPropagation();
    const formData: UseApproveOperationFormData = {
      operation: values,
    };
    cancelMutate(formData);
  };

  const handleRowClick = (row: any) => {
    const name = row.cells.find((cell: any) => cell.info.header === "name")?.value;
    if (name) {
      navigate(`/approvals/${name}`);
    }
  };

  const renderCellContent = (cell: any): any => {
    switch (cell.info.header) {
      case "body":
        return <JsonRenderer data={cell.value.toString()} />;
      case "button":
        return (
          <div>
            <CButton
              size="sm"
              className="min-width-button"
              renderIcon={Checkmark}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleApprove(cell.value, event)
              }
            >
              Approve
            </CButton>
            <CButton
              size="sm"
              kind="danger"
              className="min-width-button"
              renderIcon={SubtractAlt}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleCancel(cell.value, event)
              }
            >
              Cancel
            </CButton>
          </div>
        );
      default:
        return cell.value;
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPage(1);
  };

  if (!operationsPage) {
    return <div>Loading data, please wait...</div>;
  }

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={handleSearchChange}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>
      <DataTable
        size="md"
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  // @ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default ApprovalsIndexTable;
