import React from "react";
import { TreasuryUser } from "../../../features/organizationManagement/types/treasuryUserType";
import { useUsers } from "../../../features/usersManagement/api/useUsers";
import useAvailableUsers from "../../../features/usersManagement/hooks/useAvailableUsers";

interface UserRendererProps {
  userName: string;
}

const UserRenderer: React.FC<UserRendererProps> = ({ userName }) => {
  const availableUsers = useAvailableUsers();

  const userPage = useUsers(1, 1, `name = "${userName}"`);

  const ssoUser = userPage?.users?.[0]?.labels?.["sso-user"];

  console.log("userPage", userPage);
  console.log("availableUsers", availableUsers);
  console.log("ssoUser", ssoUser);

  const getSSOUser = (userId: string): TreasuryUser | undefined => {
    const user = availableUsers.find((user) => user.name === userId);
    return user ? user : undefined;
  };

  const user = getSSOUser(ssoUser || "");

  return (
    <>
      <div style={{ padding: "0rem" }}></div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {user?.image_url && (
          <div className="circular-image-container">
            <img src={user?.image_url} alt="User" className="circular-image" />
          </div>
        )}
        <div style={{ padding: "1rem", minWidth: "230px" }}>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            {`${user?.display_name ?? userName}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default UserRenderer;
