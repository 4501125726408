import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import { NormalizeName, ParseName } from "lib/api/name";
import { httpClientInstance } from "../../../clientInstance";
export interface AssetFormData {
  asset: string;
  name: string;
  contract: string;
  symbol: string;
  decimals: number;
  labels: any;
  notes: any;
}
interface SubmitError {
  message: string;
}

const extractChain = (input: string): string => {
  const parts = input.split("/");
  const firstTwoParts = parts.slice(0, 2);
  return firstTwoParts.join("/");
};

async function submitFormData(formData: AssetFormData) {
  console.log("form data", formData);
  const normalizedContract = NormalizeName(formData.contract);
  // const assetName: Name = [extractChain(formData.asset), normalizedContract];
  const assetName = ParseName([extractChain(formData.asset), normalizedContract], "Asset");
  const res = await Promise.allSettled([
    await httpClientInstance.createAsset([assetName.parentId!, assetName.resourceId], {
      variant: "token",
      decimals: formData.decimals,
      contract: formData.contract,
      notes: { description: formData.notes.description },
    }),
    await httpClientInstance.createSymbol(
      [`${extractChain(formData.asset)}/symbols/${formData.symbol}`],
      {
        name: `${extractChain(formData.asset)}/symbols/${formData.symbol}`,
        asset: `chains/${assetName.parentId!}/assets/${assetName.resourceId}`,
        notes: { description: formData.notes.description },
      },
    ),
  ]);
  if (res.some((r) => r.status === "rejected")) {
    // @eslint-disable-next-line
    // @ts-expect-error - we know that there is a rejected promise
    throw new Error(res.find((r) => r.status === "rejected")?.reason);
  }

  return (
    res
      .filter((r) => r.status === "fulfilled")
      // @eslint-disable-next-line
      // @ts-expect-error - we know that there is a fulfilled promise
      .find((r) => r.value.operation.state === "authorizing")?.value
  );
}

export function useAddAssetMutation() {
  console.log("use add mutation  called ");

  const opts: UseMutationOptions<
    Awaited<ReturnType<typeof submitFormData>>,
    SubmitError,
    AssetFormData
  > = {
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Asset created successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error creating Asset:", error.message);
    },
  };

  return useMutation(opts);
}
