import {
  Button,
  Modal,
  StructuredListBody,
  StructuredListCell,
  StructuredListHead,
  StructuredListRow,
  StructuredListWrapper,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CPage from "../../components/CPage";
import { commonNotificationsBuilders, useNotificationStore } from "../../stores/notifications";
import { formatDate } from "../../utils/formater";
import { useDeleteRole, useRoleById } from "./api/getRoles";
import RoleEditForm from "./components/RoleEditForm";

const RoleDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>() ?? { id: "" };
  const roleId = id ?? "";
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  const { data: roleDetails, isLoading, error } = useRoleById(roleId);
  const mutation = useDeleteRole();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDelete = () => {
    mutation.mutate(roleId, {
      onSuccess: (res) => {
        if (res.operation.state === "authorizing") {
          const notifDetails = commonNotificationsBuilders.authorizing(
            res.operation.name?.split("/")[1],
          );
          return addNotification({
            type: notifDetails.type,
            title: notifDetails.title,
            message: notifDetails.message,
          });
        }

        navigate("/roles");
        addNotification({
          type: "success",
          title: "Role deleted successfully",
        });
      },
      onError: (error) => {
        addNotification({
          type: "error",
          title: error.name,
          message: error.message,
        });
        console.error("Error deleting role:", error.message);
      },
    });
  };

  useEffect(() => {
    if (error) {
      addNotification({
        type: "error",
        title: error.name,
        message: error.message,
      });
    }
  }, [error, addNotification]);

  return (
    <CPage title="Role Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/roles")}>
          Back to Roles
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" onClick={() => setIsEditModalOpen(true)}>
            Edit
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>

      <div>{isLoading ? "Loading..." : ""}</div>
      <div>{error ? "Error loading roles details" : ""}</div>

      {!isLoading && !error && roleDetails && (
        <div>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Role Name</StructuredListCell>
                    <StructuredListCell>{roleDetails.name}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Create Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(roleDetails.create_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Update Time</StructuredListCell>
                    <StructuredListCell>
                      {formatDate(roleDetails.update_time ?? "")}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Creator</StructuredListCell>
                    <StructuredListCell>{roleDetails.creator}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Notes</StructuredListCell>
                    <StructuredListCell>
                      {roleDetails.notes && typeof roleDetails.notes === "object" ? (
                        roleDetails.notes.description
                      ) : (
                        <pre>{roleDetails.notes ?? "No notes"}</pre>
                      )}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
            <div style={{ flex: 1 }}>
              <StructuredListWrapper>
                <StructuredListHead>
                  <StructuredListRow head></StructuredListRow>
                </StructuredListHead>
                <StructuredListBody>
                  <StructuredListRow>
                    <StructuredListCell head>Credentials</StructuredListCell>
                    <StructuredListCell>
                      {typeof roleDetails.credential === "object"
                        ? roleDetails.credential.join(", ")
                        : roleDetails.credential ?? "No Credentials"}
                    </StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>User</StructuredListCell>
                    <StructuredListCell>{roleDetails.user ?? "NA"}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Version</StructuredListCell>
                    <StructuredListCell>{roleDetails.version}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>State</StructuredListCell>
                    <StructuredListCell>{roleDetails.state ?? "NA"}</StructuredListCell>
                  </StructuredListRow>
                  <StructuredListRow>
                    <StructuredListCell head>Label</StructuredListCell>
                    <StructuredListCell>
                      {roleDetails.labels && typeof roleDetails.labels === "object"
                        ? Object.values(roleDetails.labels).join(", ")
                        : roleDetails.labels ?? "No Labels"}
                    </StructuredListCell>
                  </StructuredListRow>
                </StructuredListBody>
              </StructuredListWrapper>
            </div>
          </div>
        </div>
      )}

      <Modal
        open={isModalOpen}
        modalHeading="Delete Role"
        primaryButtonText="Delete"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
      >
        <p>Are you sure you want to delete: {id}?</p>
      </Modal>

      <Modal
        open={isEditModalOpen}
        modalHeading="Edit Role"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsEditModalOpen(false)}
        onRequestSubmit={() => {
          // Trigger form submission programmatically
          document
            .getElementById("role-edit-form")
            ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }}
      >
        <RoleEditForm roleId={roleId} onClose={() => setIsEditModalOpen(false)} />
      </Modal>
    </CPage>
  );
};

export default RoleDetail;
