import {
  DataTable,
  DataTableCell,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarSearch,
} from "@carbon/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import CustomPagination, {
  DEFAULT_PAGE_SIZE,
} from "../../../components/pagination/CustomPagination";
import usePagination from "../../../components/pagination/hooks/usePagination";
import { ParseName } from "../../../lib/api/name";
import { Operation } from "../../../lib/api/types";
import { formatDate } from "../../../utils/formater";
import { useGetOperationsPage } from "../api/getOperationsPage";

interface ProcessedRow extends Operation {
  action: string;
  resource: string;
  id: string;
  create_time: string;
  create_time_ms: number;
  update_time: string;
  originalName: string;
}

const OperationsTable: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const navigate = useNavigate();

  const {
    page,
    pageSize,
    pageSizes,
    totalItems,
    setTotalItems,
    handlePageChange,
    setPage, //
  } = usePagination({
    initialPageSize: DEFAULT_PAGE_SIZE,
  });

  const { data: operationsPage, isLoading, error } = useGetOperationsPage({ pageSize: 10000 });

  useEffect(() => {
    if (error) {
      console.error("Error fetching deposits:", error);
    }
  }, [error]);

  const headers = [
    { key: "state", header: "State" },
    { key: "name", header: "Id" },
    { key: "originalName", header: "hidden" },
    { key: "create_time", header: "Created" },
    { key: "update_time", header: "Updated" },
    { key: "initiator", header: "Initiator" },
    { key: "action", header: "Action" },
    { key: "resource", header: "Resource" },
  ];

  const processedRows: ProcessedRow[] =
    operationsPage?.operations?.map((item, index) => ({
      ...item,
      id: `row-${index}`,
      create_time: formatDate(item.create_time || "") || "NA",
      create_time_ms: new Date(item.create_time || "").getTime() || 0,
      update_time: formatDate(item.update_time || "") || "NA",
      name: ParseName([item.name || ""], "Operation").resourceId || "NA",
      action: item.request?.action || "NA",
      resource: item.request?.resource || "NA",
      originalName: item.name || "NA",
    })) || [];

  const filteredRows = processedRows.filter((row) => {
    if (!searchQuery.trim()) return true;
    const lowerCaseQuery = searchQuery.toLowerCase();
    return Object.values(row).some((value) =>
      value?.toString().toLowerCase().includes(lowerCaseQuery),
    );
  });

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows, setTotalItems]);

  const sortedRows = [...filteredRows].sort((a, b) => {
    const aTime = a.create_time_ms;
    const bTime = b.create_time_ms;
    if (sortDirection === "ASC") {
      return aTime - bTime;
    } else {
      return bTime - aTime;
    }
  });

  const paginatedRows = sortedRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: DataTableCell<any>): JSX.Element => {
    switch (cell.info.header) {
      default:
        return <span>{cell.value}</span>;
    }
  };

  const handleSort = (columnName: string) => {
    if (columnName === "create_time") {
      const newSortDirection = sortDirection === "ASC" ? "DESC" : "ASC";
      setSortDirection(newSortDirection);
      setPage(1);
    }
  };

  const handleRowClick = (row: any) => {
    const id = row.cells.find((cell: any) => cell.info.header === "originalName")?.value;
    if (id) {
      navigate(`/operations/${id}`);
    }
  };

  if (isLoading) {
    return <div>Loading data, please wait...</div>;
  }

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(event.target.value);
              setPage(1);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={paginatedRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => {
                    if (header.key === "originalName") return null;

                    return (
                      // eslint-disable-next-line react/jsx-key
                      <TableHeader
                        {...getHeaderProps({
                          header,
                          isSortable: header.key === "create_time",
                          sortDirection: header.key === "create_time" ? sortDirection : undefined,
                        })}
                        onClick={() => handleSort(header.key)}
                      >
                        {header.header}
                      </TableHeader>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => {
                  if (row.id === "originalName") return null;

                  return (
                    // eslint-disable-next-line react/jsx-key
                    <TableRow
                      {...getRowProps({ row })}
                      onClick={() => handleRowClick(row)}
                      style={{ cursor: "pointer" }}
                    >
                      {row.cells
                        .filter((c) => c.info.header !== "originalName")
                        .map((cell) => (
                          <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>

      <CustomPagination
        totalItems={totalItems}
        pageSize={pageSize}
        pageSizes={pageSizes}
        page={page}
        onPageChange={handlePageChange}
      />
    </>
  );
};

export default OperationsTable;
